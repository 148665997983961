import { Footer, Header } from "./source/components";
import React, { useEffect } from "react";
import './fonts/Poppins-Regular.ttf'
import './fonts/Poppins-Bold.ttf'
import './fonts/Poppins-Black.ttf'
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import HomeContainer from "./source/screens/HomeContainer";
import Dashboard from "./source/screens/Dashboard";
import Messages from "./source/screens/Messages";
import CreatCustomer from "./source/screens/CreatCustomer";
import CreateWorkOrder from "./source/screens/CreateWorkOrder";
import MarketPlace from "./source/screens/MarketPlace";
import CreateEmployee from "./source/screens/CreateEmployee";
import MyJobs from "./source/screens/MyJobs";
import HoursWorked from "./source/screens/HoursWorked";
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import HelpInner from "./source/screens/Help-inner";
import TaskCreation from "./source/screens/TaskCreation";
import ProjectDesignations from "./source/screens/ProjectDesignations";
import AddEmployeeToProject from "./source/screens/AddEmployeeToProject";


import CreateProject from "./source/screens/CreateProject";
import Blogs from "./source/screens/Blogs";
import CreateEmployeeSchedule from "./source/screens/CreateEmployeeSchedule";
import LeaveRequest from "./source/screens/LeaveRequest";
import LoginScreen from "./source/screens/LoginContainer/LoginScreen";
import Help from "./source/screens/Help";
import CookiePolicy from "./source/screens/cookie-policy";
import PrivacyPolicy from "./source/screens/Privacy-policy";
import TermsUse from "./source/screens/terms-use";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onMessage } from "firebase/messaging";
import Message from "./source/components/Message";
import { messaging } from "./firebase.config"
import NotFound from "./source/screens/NotFound";
import UpdateSlot from "./source/screens/UpdateSlot";
import { actionType } from "./source/context/reducer";
import { useStateValue } from "./source/context/StateProvider";
import EditProject from "./source/screens/EditProject";
import Slots from "./source/screens/Slots";
import { getuserinformation } from "./utils/firebasefunctions";

export default function App() {
  const navigate = useNavigate();
  const [{ }, dispatch] = useStateValue();

  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
  }, [location])


  useEffect(() => {

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    const uid = user.uid;
getuserdata(uid)
  } else {
    if(location.pathname != "/privacypolicy"){
    navigate("/")
    }
  }
});
//     // Check if user data exists in local storage
//     const user = localStorage.getItem('user');
//     if (user) {
//       dispatch({
//         type: actionType.SET_USER,
//         payload: JSON.parse(user)
//       })
// if(location.pathname != "/privacypolicy"){
//   navigate("/home")
// }


//     } else {
//       if(location.pathname != "/privacypolicy"){
//       navigate("/")
//      }

//     }
  }, []);


 async function getuserdata(id){
    await getuserinformation("users", id).then(async (response) => {

      if(response?.delete || response?.disabled){
        const auth = getAuth();
        signOut(auth).then(() => {
          dispatch({
              type: actionType.SET_USER,
              payload: []
          })
          navigate("/")
          }).catch((error) => {
         alert("sign out error")
          });
      }else{

        dispatch({
          type: actionType.SET_USER,
          payload: response
      })
      if(location.pathname != "/privacypolicy"){
      navigate('/home')
      }

      }






  }).catch((err) => {
      console.log(err)

  })

  }

  onMessage(messaging, (payload) => {
    toast(< Message notification={payload.notification} />);
  });

  return (
    <div className="w-full flex flex-col bg-background">
{location.pathname != "/privacypolicy" &&
      <Header />
}

      <main className='w-full'>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          type="error"
          pauseOnHover
          theme="dark"
        />

        <Routes>

          <Route path="/*" element={<LoginScreen />} />
          <Route path="/home" element={<HomeContainer />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/employees" element={<HoursWorked />} />
          <Route path="/CreateEmployee" element={<CreateEmployee />} />
          <Route path="/Slots" element={<Slots />} />
          <Route path="/TaskCreation" element={<TaskCreation />} />
          <Route path="/CreateProject" element={<CreateProject />} />
          <Route path="/CreatCustomer" element={<CreatCustomer />} />
          <Route path="/MarketPlace" element={<MarketPlace />} />
          <Route path="/LeaveRequest" element={<LeaveRequest />} />
          <Route path="/MyJobs" element={<MyJobs />} />
          <Route path="/UpdateSlot" element={<UpdateSlot />} />

          <Route path="/Blogs" element={<Blogs />} />
          <Route path="/AddEmployeeToProject" element={<AddEmployeeToProject />} />

          <Route path="/ProjectDesignations" element={<ProjectDesignations />} />

          <Route path="/CreateEmployeeSchedule" element={<CreateEmployeeSchedule />} />
          <Route path="/CreateWorkOrder" element={<CreateWorkOrder />} />
          <Route path="/Messages" element={<Messages />} />
          <Route path="/EditProject" element={<EditProject />} />

          {/* <Route path="/helpinner" element={<HelpInner />} />
          <Route path="/help" element={<Help />} />
          <Route path="/cookiepolicy" element={<CookiePolicy />} /> */}
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          {/* <Route path="/termsuse" element={<TermsUse />} /> */}
          <Route element={<NotFound />} />


        </Routes>

      </main>
      {/* {!isLoginRoute && !isMessageRoute && <Footer/>} */}
    </div>
  )
}

