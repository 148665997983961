

import React, { useState, useEffect, useRef } from 'react'
import { useStateValue } from '../context/StateProvider';
import { useNavigate } from 'react-router-dom'
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import { SideMenu } from '../components'
import userlogo from '../images/user.png'
import './index.css'
import LeaveRequest from '../screens/LeaveRequest';
import { actionType } from '../context/reducer';
import CreateEmployee from '../screens/CreateEmployee';
import HoursWorked from '../screens/HoursWorked';
import CreateProject from '../screens/CreateProject';
import AddEmployeeToProject from '../screens/AddEmployeeToProject';
import Blogs from '../screens/Blogs';
import { IoIosCloseCircle } from "react-icons/io";
import { MdEditDocument, MdDelete } from "react-icons/md";

import { deleteDocument, getAllEmployeeForProject, getgeneralNestedItemdualcondition } from '../../utils/firebasefunctions';
import { downloadExcel } from "react-export-table-to-excel";
import moment from 'moment'
import { saveAs } from "file-saver";
import emailjs from 'emailjs-com';
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import { IoCheckmarkCircle } from "react-icons/io5";
import DatePicker from "react-datepicker";
import { RotatingLines } from 'react-loader-spinner'
import "react-datepicker/dist/react-datepicker.css";
import ProjectPopUp from './ProjectPopUp';
import DesignationPopUp from './DesignationPopUp';
import Slots from '../screens/Slots';
import { deleteDoc, doc } from 'firebase/firestore';
import { firestored } from '../../firebase.config';
import Confirmationpopup from './Confirmationpopup';
import { TbRuler } from 'react-icons/tb';

const AdminHome = (props) => {
  const header = ["Hours Status" , "Emp ID", "Name", "Project Title", 'Designation', "Hourly Salary", 'Check In', 'Lunch In', 'Lunch Out', 'Check Out', 'Total Break Time', 'Total Time Of Day', 'NORMAL HRS', 'EVENING HRS', 'NIGHT HRS', 'HOURLY SALARY NORMAL', 'HOURLY SALARY EVENING', 'HOURLY SALARY NIGHT', 'TOTAL SALARY', 'INVOICING AMOUNT WITH A MULTIPLICATOR', 'Fixed Amount'];
  const [{ clientemployees, clientsupervisors, clientprojects, selectedtile, user, employees, clients, projects, allclients }, dispatch] = useStateValue();
  const [designationcolors, setdesignationcolors] = useState([]);
  const [designation, setdesignation] = useState([]);
  const [generalemployeecheckindata, setgeneralemployeecheckindata] = useState([]);
  const [employeecheckindata, setemployeecheckindata] = useState([]);
  const [employeedata, setemployeedata] = useState();
  const [loading, setloading] = useState(false);
  const [recorddeletionloader, setrecorddeletionloader] = useState(false);
  const [projectselectionpopup, setprojectselectionpopup] = useState(false);
  const [confirmdeletepopup, setconfirmdeletepopup] = useState(false);
  const [deletionmessage, setdeletionmessage] = useState("");
  const [deletiondata, setdeletiondata] = useState("");
  const [startdate, setstartdate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [enddate, setenddate] = useState(new Date(new Date().setHours(23, 59, 59, 59)));
  const [project, setproject] = useState("");
  const [designationselectionpopup, setdesignationselectionpopup] = useState(false);

  const designationselectionpopupref = useRef(null)

  const navigate = useNavigate();

  useEffect(() => {
    getgeneralhours()

  }, [startdate, enddate,  project])


  useEffect(() => {
    const handleClickOutside = (event) => {


      if (designationselectionpopupref.current && !designationselectionpopupref.current.contains(event.target)) {
        setdesignationselectionpopup(false);
      }





    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ designationselectionpopupref]);


  const getgeneralhours = async () => {
    // employees
    // designation
    // console.log(JSON.stringify(project))
    setloading(true)
    setgeneralemployeecheckindata([])
    let data = []
    if (project == "") {
let dataset = []
for (const key in employees) {
  dataset.push(employees[key].id)
}


        await getgeneralNestedItemdualcondition("slots", dataset, "checkindatetime", ">=", startdate.getTime(), "checkindatetime", "<=", enddate.getTime()).then(async (response) => {
          let responsedata = [...response]
          for (const index in responsedata) {
            responsedata[index]["employeedata"] = employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data })
            responsedata[index]["name"] = employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.firstname + " " + data.lastname })
            responsedata[index]["designation"] = employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.projectdesignation })
            responsedata[index]["profilepicture"] = employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.profilepicture })
            responsedata[index]["employeeidd"] = employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.employeeid })
          }
          data = [...data, ...responsedata]
        }).catch((err) => {
          console.log(err)
          setloading(false)
        })

    } else {
      let dataset = []
for (const key in project.employees) {
  dataset.push(project.employees[key])
}

        await getgeneralNestedItemdualcondition("slots", dataset, "checkindatetime", ">=", startdate.getTime(), "checkindatetime", "<=", enddate.getTime(), "projectid", "==", project?.id).then(async (response) => {


          let responsedata = [...response]
          for (const index in responsedata) {
            responsedata[index]["employeedata"] = employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data })
            responsedata[index]["name"] = employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.firstname + " " + data.lastname })
            responsedata[index]["designation"] =employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.projectdesignation })
            responsedata[index]["profilepicture"] = employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.profilepicture })
            responsedata[index]["employeeidd"] = employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.employeeid })
          }
          data = [...data, ...responsedata]
        }).catch((err) => {
          console.log(err)
          setloading(false)
        })

    }



    console.log("this is all the data in the world " + JSON.stringify(data))
    setgeneralemployeecheckindata(data)
    setloading(false)
  }
  const getlocationaddress = (lat, long) => {
    fromLatLng(lat, long)
      .then(({ results }) => {

        alert(results[0].formatted_address)
      })
      .catch(console.error);
  }

  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function hexToRgba(hex, alpha) {
    // Remove the '#' character if it exists
    hex = hex.replace(/^#/, '');

    // Parse the r, g, b values
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Ensure alpha is between 0 and 1
    alpha = Math.max(0, Math.min(1, alpha));

    // Return the rgba color string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  const getallprojectemployees = async (projectid) => {
    await getAllEmployeeForProject("users", "usertype", "==", "Employee", projectid).then(async (response) => {

      dispatch({
        type: actionType.STORE_PROJECTEMPLOYEES,
        payload: response
      })

      // toast.success("Signed in Successfully")
      // navigate('/home')
    }).catch((err) => {
      alert(err)
    })
  }

   const generateAndSaveCSV = (headers, data, name) => {
    // Convert headers and data to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      [headers.join(","), ...data.map((row) => row.join(","))].join("\n");

    // Convert to Blob
    const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Save the file locally
    saveAs(csvBlob, `${name}.csv`);

    return csvBlob; // Return Blob for further use
  };

  const groupByProjectId = (data) => {

    let sortedprojectdata = []
    for (const key in data){
      if(sortedprojectdata.find(item => item.projectid == data[key].projectid) == undefined){

        sortedprojectdata.push({
          "projectid": data[key].projectid,
          "slotsdata": []
        })
      }
      const projectindex = sortedprojectdata.findIndex(itemdata => itemdata.projectid === data[key].projectid);
      sortedprojectdata[projectindex]?.slotsdata.push(data[key])

    }


    return sortedprojectdata; // Convert Map values to an array of arrays
  };

const bulkDeleteRecords = async (recordIds) => {


  try {
    const deletePromises = recordIds.map(async (id) => {
      const docRef = doc(firestored, "slots", id.toString()); // Replace "yourCollectionName"
      await deleteDoc(docRef);
      console.log(`Deleted record: ${id}`);
    });

    await Promise.all(deletePromises);
    toast.success("All selected records deleted successfully!")
     getgeneralhours()
       setrecorddeletionloader(false)

  } catch (error) {
    console.error("Error deleting records:", error);
    toast.warn("Error deleting records")
       setrecorddeletionloader(false)

  }
};

const handleDeleteGeneral = async () => {

setrecorddeletionloader(true)


  const groupedData = groupByProjectId(generalemployeecheckindata);
 let attachmentdata = []

  for (const dataindex in groupedData){

  let newdata = []
  let newgeneralemployeecheckindata = [...groupedData[dataindex].slotsdata]
  let data
  newgeneralemployeecheckindata.map((val, id) => {
    if(val?.checkintime != undefined && val?.checkouttime != undefined ){
     data = clients.find(project => project.id === val?.projectid);
    const hoursWorked = data?.eveningPercentage || data?.normalPercentage || data?.nightPercentage ? calculateShiftHours(shiftmaker(data), val?.checkintime, val?.checkouttime) : {}
    newgeneralemployeecheckindata[id]["hoursWorked"] = hoursWorked
    const normalAmount = val?.priceperhour ? calculateSalary(hoursWorked?.Normal?.hours, hoursWorked?.Normal?.minutes, val?.priceperhour, shifts[0]?.percentage) : 0
    const eveningAmount = val?.priceperhour ? calculateSalary(hoursWorked?.Evening?.hours, hoursWorked?.Evening?.minutes, val?.priceperhour, shifts[1]?.percentage) : 0
    const nightAmount = val?.priceperhour ? calculateSalary(hoursWorked?.Night?.hours, hoursWorked?.Night?.minutes, val?.priceperhour, shifts[2]?.percentage) : 0
    const totalSalary = (normalAmount > 0 ? normalAmount : 0) + (eveningAmount > 0 ? eveningAmount : 0) + (nightAmount > 0 ? nightAmount : 0)
    const mutiplicatorPrice = data?.multiplicatorPercentageStatus ? totalSalary * data.multiplicatorPercentage : 0
    const totalHours = val?.minutes ? parseInt(val?.minutes / 60) + " hrs " + parseInt(val?.minutes % 60) + " mins" : 0 + " mins"
    const fixedPrice = data?.fixedprice > 0 ? calculateFixedAmount(val?.minutes ? parseInt(val?.minutes / 60) : 0, val?.minutes ? parseInt(val?.minutes % 60) : 0, data.fixedprice) : 0
    designation.find(data => data.selected == true) ?
      designation.find(data => data.selected == true) == undefined || designation.find(data => data.selected == true && val.projectdesignation == data.designation) != undefined &&
      newdata.push([val?.hoursapproved == true ? "Approved" : val?.hoursapproved == false ? "Rejected" : "Pending" , val.employeeidd, val.name ? val.name : 'NA', project.title ? project.title : data ? data.title : 'NA', val.projectdesignation ? val.projectdesignation : 'NA', val?.priceperhour,  val.checkindatetime ? moment(val.checkindatetime).format('YYYY-MM-DD hh:mm:ss a') : 'NA', val.lunchstarttime && val.lunchallowed ? `${val.sheduleddate + ' ' + val.lunchstarttime}` : 'NA', val.lunchendtime && val.lunchallowed ? `${val.sheduleddate + ' ' + val.lunchendtime}` : 'NA', val.checkoutdatetime ? moment(val.checkoutdatetime).format('YYYY-MM-DD hh:mm:ss a') : 'NA', val.lunchstarttime && val.lunchendtime && val.lunchallowed ? timeDifference(val.sheduleddate + ' ' + val.lunchstarttime, val.sheduleddate + ' ' + val.lunchendtime) : 'NA', totalHours, hoursWorked?.Normal ? hoursWorked.Normal.hours + ' hrs ' + hoursWorked.Normal.minutes + ' mins ' : 'NA', hoursWorked?.Evening ? hoursWorked.Evening.hours + ' hrs ' + hoursWorked.Evening.minutes + ' mins ' : 'NA', hoursWorked?.Night ? hoursWorked.Night.hours + ' hrs ' + hoursWorked.Night.minutes + ' mins ' : 'NA', normalAmount.toFixed(2), eveningAmount.toFixed(2), nightAmount.toFixed(2), totalSalary ? totalSalary.toFixed(2) : 0, mutiplicatorPrice.toFixed(2), fixedPrice.toFixed(2)])
      :
      newdata.push([val?.hoursapproved == true ? "Approved" : val?.hoursapproved == false ? "Rejected" : "Pending" ,  val.employeeidd, val.name ? val.name : 'NA', project.title ? project.title : data ? data.title : 'NA', val.projectdesignation ? val.projectdesignation : 'NA',  val?.priceperhour, val.checkindatetime ? moment(val.checkindatetime).format('YYYY-MM-DD hh:mm:ss a') : 'NA', val.lunchstarttime && val.lunchallowed ? `${val.sheduleddate + ' ' + val.lunchstarttime}` : 'NA', val.lunchendtime && val.lunchallowed ? `${val.sheduleddate + ' ' + val.lunchendtime}` : 'NA', val.checkoutdatetime ? moment(val.checkoutdatetime).format('YYYY-MM-DD hh:mm:ss a') : 'NA', val.lunchstarttime && val.lunchendtime && val.lunchallowed ? timeDifference(val.sheduleddate + ' ' + val.lunchstarttime, val.sheduleddate + ' ' + val.lunchendtime) : 'NA', totalHours, hoursWorked?.Normal ? hoursWorked.Normal.hours + ' hrs ' + hoursWorked.Normal.minutes + ' mins ' : 'NA', hoursWorked?.Evening ? hoursWorked.Evening.hours + ' hrs ' + hoursWorked.Evening.minutes + ' mins ' : 'NA', hoursWorked?.Night ? hoursWorked.Night.hours + ' hrs ' + hoursWorked.Night.minutes + ' mins ' : 'NA', normalAmount.toFixed(2), eveningAmount.toFixed(2), nightAmount.toFixed(2), totalSalary ? totalSalary.toFixed(2) : 0, mutiplicatorPrice.toFixed(2), fixedPrice.toFixed(2)])
  }

})

  const totalHoursworkedforallemployees = newgeneralemployeecheckindata.reduce((sum, row) => sum + (row?.minutes ? row.minutes : 0), 0);
  const totalnormalHoursworkedforallemployees = newgeneralemployeecheckindata.reduce((sum, row) => sum + (row?.hoursWorked?.Normal ? row.hoursWorked?.Normal?.totalminutes : 0), 0);
  const totaleveningHoursworkedforallemployees = newgeneralemployeecheckindata.reduce((sum, row) => sum + (row?.hoursWorked?.Evening ? row.hoursWorked?.Evening?.totalminutes : 0), 0);
  const totalnightHoursworkedforallemployees = newgeneralemployeecheckindata.reduce((sum, row) => sum + (row?.hoursWorked?.Night ? row.hoursWorked?.Night?.totalminutes : 0), 0);


  newdata.push(["" ,  "Total", "", "", "",  "", "", "", "", "", "", parseInt(totalHoursworkedforallemployees / 60) + " hrs " + parseInt(totalHoursworkedforallemployees % 60) + " mins",parseInt(totalnormalHoursworkedforallemployees / 60) + " hrs " + parseInt(totalnormalHoursworkedforallemployees % 60) + " mins", parseInt(totaleveningHoursworkedforallemployees / 60) + " hrs " + parseInt(totaleveningHoursworkedforallemployees % 60) + " mins" , parseInt(totalnightHoursworkedforallemployees / 60) + " hrs " + parseInt(totalnightHoursworkedforallemployees % 60) + " mins", "", "", "", "", "", ""])
  // console.log(newdata);


  // downloadExcel({
  //   fileName: data.title.toString(),
  //   sheet: data.title,
  //   tablePayload: {
  //     header,
  //     body: newdata,
  //   },
  // });



   attachmentdata.push({
   name: data.title.toString(),
   dataarray: newdata
   })

  //  const csvBlob = generateAndSaveCSV(header, newdata); // Generate & Save CSV file
  //  // Convert Blob to File
  //  const file = new File([csvBlob], "GeneralSlots.csv", { type: "text/csv" });



    }


    const formData = new FormData();
    formData.append("email", user.email);
    formData.append("from_name", "Deleted Slots");
    formData.append("message", `${moment(startdate).format('YYYY-MM-DD')} - ${moment(enddate).format('YYYY-MM-DD')}`);
    formData.append("service_id", "service_5z77uee");
    formData.append("template_id", "template_jw6dv7e");
    formData.append("user_id", "pUoX1kN2nzw7N8UsM");


    // formData.append("attachment", file);

   attachmentdata.forEach((file, index) => {
      const csvBlob = generateAndSaveCSV(header, file.dataarray, index.toString()); // Generate & Save CSV file
      // Convert Blob to File
      const filedata = new File([csvBlob], `${file.name}.csv`, { type: "text/csv" });
      formData.append(`attachment${index + 1}`, filedata);
      // console.log("filedata " + index + "  " + JSON.stringify(file.dataarray))
      // console.log("file " + index + "  " + JSON.stringify(filedata))
    });


    try {
     const response =  await fetch("https://api.emailjs.com/api/v1.0/email/send-form", {
       method: "POST",
       body: formData,
     });

     if (response.ok) {
     let recorids = []
       console.log("Email sent successfully!");
       toast.success("Deleted records mailed to Admin!")
    for (const key in groupedData){
      for (const innerkey in groupedData[key].slotsdata){
      recorids.push(groupedData[key].slotsdata[innerkey].schedulecreationdate)
    // console.log( JSON.stringify(groupedData[key].slotsdata[innerkey].schedulecreationdate))
    };
    }
    bulkDeleteRecords(recorids)

     } else {
       console.error("Failed to send email:", response);
       setrecorddeletionloader(false)

     }
   } catch (error) {
     console.error("Error sending email:", error);
        setrecorddeletionloader(false)

   }
    // console.log("All attachements " + JSON.stringify(attachmentdata))
    // const formData = new FormData();
    // formData.append("email", "humzasamiullah@gmail.com");
    // formData.append("message", "day 2 2.");
    // formData.append("service_id", "service_5z77uee");
    // formData.append("template_id", "template_jw6dv7e");
    // formData.append("user_id", "pUoX1kN2nzw7N8UsM");
    // formData.append(`Attachment1`, filedata);
    // formData.append("Attachment", file); // Attach the Excel file

    // attachmentdata.forEach((file, index) => {
    //   const csvBlob = generateAndSaveCSV(header, file.dataarray); // Generate & Save CSV file
    //   // Convert Blob to File
    //   const filedata = new File([csvBlob], `${index.toString()}.csv`, { type: "text/csv" });
    //   formData.append(`Attachment${index + 1}`, filedata);
    //   console.log("filedata " + index + "  " + JSON.stringify(file.dataarray))
    //   console.log("file " + index + "  " + JSON.stringify(filedata))
    // });

}
  const handleDownloadExcelGeneral = () => {

    let newdata = []
    let newgeneralemployeecheckindata = [...generalemployeecheckindata]
    newgeneralemployeecheckindata.map((val, id) => {

      if(val?.checkintime != undefined && val?.checkouttime != undefined ){
      const data = clients.find(project => project.id === val?.projectid);
      const hoursWorked = data?.eveningPercentage || data?.normalPercentage || data?.nightPercentage ? calculateShiftHours(shiftmaker(data), val?.checkintime, val?.checkouttime) : {}
      newgeneralemployeecheckindata[id]["hoursWorked"] = hoursWorked
      const normalAmount = val?.priceperhour ? calculateSalary(hoursWorked?.Normal?.hours, hoursWorked?.Normal?.minutes, val?.priceperhour, shifts[0]?.percentage) : 0
      const eveningAmount = val?.priceperhour ? calculateSalary(hoursWorked?.Evening?.hours, hoursWorked?.Evening?.minutes, val?.priceperhour, shifts[1]?.percentage) : 0
      const nightAmount = val?.priceperhour ? calculateSalary(hoursWorked?.Night?.hours, hoursWorked?.Night?.minutes, val?.priceperhour, shifts[2]?.percentage) : 0
      const totalSalary = (normalAmount > 0 ? normalAmount : 0) + (eveningAmount > 0 ? eveningAmount : 0) + (nightAmount > 0 ? nightAmount : 0)
      const mutiplicatorPrice = data?.multiplicatorPercentageStatus ? totalSalary * data.multiplicatorPercentage : 0
      const totalHours = val?.minutes ? parseInt(val?.minutes / 60) + " hrs " + parseInt(val?.minutes % 60) + " mins" : 0 + " mins"
      const fixedPrice = data?.fixedprice > 0 ? calculateFixedAmount(val?.minutes ? parseInt(val?.minutes / 60) : 0, val?.minutes ? parseInt(val?.minutes % 60) : 0, data.fixedprice) : 0
      designation.find(data => data.selected == true) ?
        designation.find(data => data.selected == true) == undefined || designation.find(data => data.selected == true && val.projectdesignation == data.designation) != undefined &&
        newdata.push([val?.hoursapproved == true ? "Approved" : val?.hoursapproved == false ? "Rejected" : "Pending" , val.employeeidd, val.name ? val.name : 'NA', project.title ? project.title : data ? data.title : 'NA', val.projectdesignation ? val.projectdesignation : 'NA', val?.priceperhour,  val.checkindatetime ? moment(val.checkindatetime).format('YYYY-MM-DD hh:mm:ss a') : 'NA', val.lunchstarttime && val.lunchallowed ? `${val.sheduleddate + ' ' + val.lunchstarttime}` : 'NA', val.lunchendtime && val.lunchallowed ? `${val.sheduleddate + ' ' + val.lunchendtime}` : 'NA', val.checkoutdatetime ? moment(val.checkoutdatetime).format('YYYY-MM-DD hh:mm:ss a') : 'NA', val.lunchstarttime && val.lunchendtime && val.lunchallowed ? timeDifference(val.sheduleddate + ' ' + val.lunchstarttime, val.sheduleddate + ' ' + val.lunchendtime) : 'NA', totalHours, hoursWorked?.Normal ? hoursWorked.Normal.hours + ' hrs ' + hoursWorked.Normal.minutes + ' mins ' : 'NA', hoursWorked?.Evening ? hoursWorked.Evening.hours + ' hrs ' + hoursWorked.Evening.minutes + ' mins ' : 'NA', hoursWorked?.Night ? hoursWorked.Night.hours + ' hrs ' + hoursWorked.Night.minutes + ' mins ' : 'NA', normalAmount.toFixed(2), eveningAmount.toFixed(2), nightAmount.toFixed(2), totalSalary ? totalSalary.toFixed(2) : 0, mutiplicatorPrice.toFixed(2), fixedPrice.toFixed(2)])
        :
        newdata.push([val?.hoursapproved == true ? "Approved" : val?.hoursapproved == false ? "Rejected" : "Pending" ,  val.employeeidd, val.name ? val.name : 'NA', project.title ? project.title : data ? data.title : 'NA', val.projectdesignation ? val.projectdesignation : 'NA',  val?.priceperhour, val.checkindatetime ? moment(val.checkindatetime).format('YYYY-MM-DD hh:mm:ss a') : 'NA', val.lunchstarttime && val.lunchallowed ? `${val.sheduleddate + ' ' + val.lunchstarttime}` : 'NA', val.lunchendtime && val.lunchallowed ? `${val.sheduleddate + ' ' + val.lunchendtime}` : 'NA', val.checkoutdatetime ? moment(val.checkoutdatetime).format('YYYY-MM-DD hh:mm:ss a') : 'NA', val.lunchstarttime && val.lunchendtime && val.lunchallowed ? timeDifference(val.sheduleddate + ' ' + val.lunchstarttime, val.sheduleddate + ' ' + val.lunchendtime) : 'NA', totalHours, hoursWorked?.Normal ? hoursWorked.Normal.hours + ' hrs ' + hoursWorked.Normal.minutes + ' mins ' : 'NA', hoursWorked?.Evening ? hoursWorked.Evening.hours + ' hrs ' + hoursWorked.Evening.minutes + ' mins ' : 'NA', hoursWorked?.Night ? hoursWorked.Night.hours + ' hrs ' + hoursWorked.Night.minutes + ' mins ' : 'NA', normalAmount.toFixed(2), eveningAmount.toFixed(2), nightAmount.toFixed(2), totalSalary ? totalSalary.toFixed(2) : 0, mutiplicatorPrice.toFixed(2), fixedPrice.toFixed(2)])
    }

  })

    const totalHoursworkedforallemployees = newgeneralemployeecheckindata.reduce((sum, row) => sum + (row?.minutes ? row.minutes : 0), 0);
    const totalnormalHoursworkedforallemployees = newgeneralemployeecheckindata.reduce((sum, row) => sum + (row?.hoursWorked?.Normal ? row.hoursWorked?.Normal?.totalminutes : 0), 0);
    const totaleveningHoursworkedforallemployees = newgeneralemployeecheckindata.reduce((sum, row) => sum + (row?.hoursWorked?.Evening ? row.hoursWorked?.Evening?.totalminutes : 0), 0);
    const totalnightHoursworkedforallemployees = newgeneralemployeecheckindata.reduce((sum, row) => sum + (row?.hoursWorked?.Night ? row.hoursWorked?.Night?.totalminutes : 0), 0);


    newdata.push(["" ,  "Total", "", "", "",  "", "", "", "", "", "", parseInt(totalHoursworkedforallemployees / 60) + " hrs " + parseInt(totalHoursworkedforallemployees % 60) + " mins",parseInt(totalnormalHoursworkedforallemployees / 60) + " hrs " + parseInt(totalnormalHoursworkedforallemployees % 60) + " mins", parseInt(totaleveningHoursworkedforallemployees / 60) + " hrs " + parseInt(totaleveningHoursworkedforallemployees % 60) + " mins" , parseInt(totalnightHoursworkedforallemployees / 60) + " hrs " + parseInt(totalnightHoursworkedforallemployees % 60) + " mins", "", "", "", "", "", ""])
    // console.log(newdata);

    downloadExcel({
      fileName: "General Sheet",
      sheet: "General-Sheet",
      tablePayload: {
        header,
        body: newdata,
      },
    });
  }
  const handleDownloadExcelEmployeeData = () => {
    let newdata = []
    employeecheckindata.map((val, id) => {
      const data = clients.find(project => project.id === val?.projectid);
      const hoursWorked = data?.eveningPercentage || data?.normalPercentage || data?.nightPercentage ? calculateShiftHours(shiftmaker(data), val?.checkintime, val?.checkouttime) : {}
      const normalAmount = val?.priceperhour ? calculateSalary(hoursWorked?.Normal?.hours, hoursWorked?.Normal?.minutes, val?.priceperhour, shifts[0]?.percentage) : 0
      const eveningAmount = val?.priceperhour ? calculateSalary(hoursWorked?.Evening?.hours, hoursWorked?.Evening?.minutes, val?.priceperhour, shifts[1]?.percentage) : 0
      const nightAmount = val?.priceperhour ? calculateSalary(hoursWorked?.Night?.hours, hoursWorked?.Night?.minutes, val?.priceperhour, shifts[2]?.percentage) : 0
      const totalSalary = (normalAmount > 0 ? normalAmount : 0) + (eveningAmount > 0 ? eveningAmount : 0) + (nightAmount > 0 ? nightAmount : 0)
      const mutiplicatorPrice = data?.multiplicatorPercentageStatus ? totalSalary * data.multiplicatorPercentage : 0
      const totalHours = val?.minutes ? parseInt(val?.minutes / 60) + " hrs " + parseInt(val?.minutes % 60) + " mins" : 0 + " mins"
      const fixedPrice = data?.fixedprice > 0 ? calculateFixedAmount(val?.minutes ? parseInt(val?.minutes / 60) : 0, val?.minutes ? parseInt(val?.minutes % 60) : 0, data.fixedprice) : 0
      newdata.push([val.employeeid, employeedata?.firstname ? employeedata?.firstname + " " + employeedata?.lastname : 'NA', project.title ? project.title : data ? data.title : 'NA', val?.projectdesignation ? val?.projectdesignation : 'NA', val.checkindatetime ? moment(val.checkindatetime).format('YYYY-MM-DD hh:mm:ss a') : 'NA', val.lunchstarttime && val.lunchallowed ? `${val.sheduleddate + ' ' + val.lunchstarttime}` : 'NA', val.lunchendtime && val.lunchallowed ? `${val.sheduleddate + ' ' + val.lunchendtime}` : 'NA', val.checkoutdatetime ? moment(val.checkoutdatetime).format('YYYY-MM-DD hh:mm:ss a') : 'NA', val.lunchstarttime && val.lunchendtime && val.lunchallowed ? timeDifference(val.sheduleddate + ' ' + val.lunchstarttime, val.sheduleddate + ' ' + val.lunchendtime) : 'NA', totalHours, hoursWorked?.Normal ? hoursWorked.Normal.hours + ' hrs ' + hoursWorked.Normal.minutes + ' mins ' : 'NA', hoursWorked?.Evening ? hoursWorked.Evening.hours + ' hrs ' + hoursWorked.Evening.minutes + ' mins ' : 'NA', hoursWorked?.Night ? hoursWorked.Night.hours + ' hrs ' + hoursWorked.Night.minutes + ' mins ' : 'NA', normalAmount.toFixed(2), eveningAmount.toFixed(2), nightAmount.toFixed(2), totalSalary ? totalSalary.toFixed(2) : 0, mutiplicatorPrice.toFixed(2), fixedPrice.toFixed(2)])
    })
    // console.log(newdata);
    downloadExcel({
      fileName: `${employeedata?.firstname ? employeedata?.firstname + " " + employeedata?.lastname : 'Employee Sheet'}`,
      sheet: "Employee-Sheet",
      tablePayload: {
        header,
        body: newdata,
      },
    });
  }
  // Define the shifts with their start and end times
  const shifts = [];
  function shiftmaker(data) {
    console.log(data);
    if (data?.normalstarttime && data?.normalstarttime) {
      shifts.push({ name: 'Normal', start: data?.normalstarttime, end: data?.normalendtime, percentage: data?.normalPercentage })
    }
    if (data?.eveningstarttime && data?.eveningstarttime) {

      shifts.push({ name: 'Evening', start: data?.eveningstarttime, end: data?.eveningendtime, percentage: data?.eveningPercentage })
    }
    if (data?.nightstarttime && data?.nightstarttime) {

      shifts.push({ name: 'Night', start: data?.nightstarttime, end: data?.nightendtime, percentage: data?.nightPercentage })
    }


    return shifts
  }

  // Convert time string to Date object for a given date
  // Convert time string to Date object for a given date
  function parseTime(timeStr, baseDate) {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), hours , minutes );
  }

  // Calculate hours worked within a time range in hours and minutes
  function calculateOverlap(start1, end1, start2, end2) {
    const start = Math.max(start1.getTime(), start2.getTime());
    const end = Math.min(end1.getTime(), end2.getTime());

    if (start >= end) return { hours: 0, minutes: 0 }; // No overlap

    const overlapMillis = end - start;
    const hours = Math.floor(overlapMillis / (1000 * 60 * 60));
    const minutes = Math.floor((overlapMillis % (1000 * 60 * 60)) / (1000 * 60));
    return { hours, minutes };
  }

  // Calculate hours worked in each shift and return in hours and minutes
  function calculateShiftHours(shifts, workStartTime, workEndTime) {
    let shiftHours = {};

    const baseDate = new Date(); // Use current date as base

    const workStart = parseTime(workStartTime, baseDate);
    const workEnd =  parseTime(workEndTime, baseDate) ;

    shifts.forEach(shift => {
      const shiftStart =  parseTime(shift.start, baseDate) ;
      const shiftEnd =  parseTime(shift.end, baseDate) ;

      if (shift.start > shift.end) {
        // Shift spans overnight
        const shiftEndMidnight = new Date(baseDate);
        shiftEndMidnight.setDate(shiftEndMidnight.getDate() + 1);
        shiftEndMidnight.setHours(shiftEnd.getHours());
        shiftEndMidnight.setMinutes(shiftEnd.getMinutes());

        const overlap1 = calculateOverlap(workStart, workEnd, shiftStart, shiftEndMidnight);
        const overlap2 = calculateOverlap(workStart, workEnd, new Date(baseDate), shiftEnd);

        shiftHours[shift.name] = {
          hours: overlap1.hours + overlap2.hours,
          minutes: overlap1.minutes + overlap2.minutes
        };
      } else {
        // Shift within a single day
        const overlap = calculateOverlap(workStart, workEnd, shiftStart, shiftEnd);
        shiftHours[shift.name] = overlap;
      }
    });

    // Convert minutes to hours if greater than 60
    for (const shift in shiftHours) {
      const shiftTime = shiftHours[shift];
      if (shiftTime.minutes >= 60) {
        shiftTime.hours += Math.floor(shiftTime.minutes / 60);
        shiftTime.minutes = shiftTime.minutes % 60;
      }
    }

    shiftHours.Normal["totalminutes"] = (shiftHours?.Normal?.hours * 60) + (shiftHours?.Normal?.minutes)
    shiftHours.Evening["totalminutes"] = (shiftHours?.Evening?.hours * 60) + (shiftHours?.Evening?.minutes)
    shiftHours.Night["totalminutes"] = (shiftHours?.Night?.hours * 60) + (shiftHours?.Night?.minutes)


    return shiftHours;
  }
  function calculateSalary(hoursWorked, minutesWorked, perHourRate, percentage) {
    // console.log(hoursWorked, minutesWorked, perHourRate, percentage);
    // Convert total hours and minutes into total hours
    const totalHours = hoursWorked + (minutesWorked / 60);


    // Apply percentage adjustment
    const adjustmentAmount = perHourRate * (percentage / 100);
    // Calculate base salary

    // const baseSalary = totalHours * perHourRate;
    const finalSalary = totalHours * adjustmentAmount;
    // console.log(totalHours, adjustmentAmount);
    return finalSalary ? finalSalary : 0;
  }
  function calculateFixedAmount(hours, minutes, perHourRate) {
    // Convert minutes to hours
    const minutesInHours = minutes / 60;

    // Total hours including minutes
    const totalHours = hours + minutesInHours;
    // Calculate total amount
    const totalAmount = totalHours * perHourRate;

    return totalAmount ? totalAmount : 0;
  }
  function timeDifference(date1, date2) {
    const startTime = new Date(date1);
    const endTime = new Date(date2);
    const differenceInMs = endTime - startTime;
    const totalMinutes = Math.floor(differenceInMs / (1000 * 60));

    // Convert total minutes to hours and minutes
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours} hrs ${minutes} mins`;

    // Helper function to convert time string (hh:mm) to minutes since midnight
    // function timeToMinutes(time) {
    //   const [hours, minutes] = time.split(':').map(Number);
    //   return hours * 60 + minutes;
    // }

    // // Convert times to minutes
    // const startMinutes = timeToMinutes(startTime);
    // const endMinutes = timeToMinutes(endTime);

    // // Calculate the difference in minutes
    // let differenceInMinutes = endMinutes - startMinutes;

    // // If the difference is negative, it means the end time is on the next day
    // if (differenceInMinutes < 0) {
    //   differenceInMinutes += 24 * 60; // Add 24 hours worth of minutes
    // }

    // // Convert difference to hours and minutes
    // const hours = Math.floor(differenceInMinutes / 60);
    // const minutes = differenceInMinutes % 60;

    // return `${hours + ' hrs ' + minutes + ' mins'}`;
  }

  async function deletesinglerecord(){

    await deleteDocument("slots", deletiondata.schedulecreationdate).then(async (response) => {
  if(response == true){
   setdeletiondata("")
   getgeneralhours()
  }else{
    toast.error("Your slot can not be deleted at the moment. Please try again later!")
  }

      }).catch((err) => {
        toast.error("Your slot can not be deleted at the moment. Please try again later!")
        alert(err)
      })
  }
  function Dashboard() {
    return (
      <div className='w-full mt-[60px] md:mt-[0px]'>


        {user?.usertype == "SuperAdmin" &&
          <>
            <p className='text-textcolor text-[22px] PoppinsRegular font-semibold'>Client's</p>


            <div className=" overflow-x-auto  border border-gray-200 mt-5  bg-cardOverlay rounded-xl  drop-shadow-lg overflow-y-scroll m-h-[45%]">
              <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                <thead className="ltr:text-left rtl:text-right">
                  <tr>
                    <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor"></th>
                    <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Name</th>
                    <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Projects</th>
                    <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Employees</th>
                    <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Email</th>
                    <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Phone Number</th>


                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 text-center">
                  {allclients && allclients.map((item, index) =>
                  (
                    <tr
                    key = {index.toString()}
                    className='hover:bg-[#0989B826]/10 cursor-pointer' onClick={() => {
                    }}>
                      <td className="whitespace-nowrap px-4 py-2 flex justify-center font-medium PoppinsRegular text-textcolor">
                        <img src={item.profilepicture != "" ? item.profilepicture : userlogo} className='w-[30px] h-[30px] lg:w-[40px] lg:h-[40px] rounded-full' />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item.firstname} {item.lastname}</td>
                      <td className="whitespace-nowrap px-16 py-2 PoppinsRegular font-medium text-textcolor">{clients?.filter(function (data) { return data.createdby == item.id })?.length ? clients?.filter(function (data) { return data.createdby == item.id })?.length : 0}</td>
                      <td className="whitespace-nowrap px-16 py-2 PoppinsRegular font-medium text-textcolor">{employees?.filter(function (data) { return data.createdby == item.id })?.length ? employees?.filter(function (data) { return data.createdby == item.id })?.length : 0}</td>
                      <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item.email}</td>
                      <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item.phonenumber}</td>
                    </tr>
                  )
                  )
                  }
                </tbody>
              </table>
            </div>
          </>}

          <div className='mt-12'>
          <p className='text-textcolor text-[22px] PoppinsRegular font-semibold'>Projects</p>
        </div>

        <div className=" overflow-x-auto  border border-gray-200 mt-5  bg-cardOverlay rounded-xl  drop-shadow-lg overflow-y-scroll m-h-[45%]">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
            <thead className="ltr:text-left rtl:text-right">
              <tr>
                <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor"></th>
                <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Project</th>
                <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Employees</th>
                <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Email</th>
                <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Phone Number</th>
               {user?.usertype == "SuperAdmin" || user?.usertype == "Admin" ||  user?.usertype == "Supervisor" && user?.scheduleaccess ?
               <>
                <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor"></th>
                <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor"></th>
                </> : null}
                {(user?.usertype == "SuperAdmin") || (user?.usertype == "Admin") ||  (user?.usertype == "Supervisor" && user?.editaccess) ?
                <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor"></th>
:null}

              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200 text-center">
              {clients && clients.map((item, index) =>
              (
                <tr
                key = {index.toString()}
                className='hover:bg-[#0989B826]/10 cursor-pointer' onClick={() => {
                }}>
                  <td className="whitespace-nowrap px-4 py-2 flex justify-center font-medium PoppinsRegular text-textcolor">
                    <img src={item.logo != "" ? item.logo : userlogo} className='w-[30px] h-[30px] lg:w-[40px] lg:h-[40px] rounded-full' />
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item.title}</td>
                  <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item?.employees != undefined ? item?.employees.length : 0}</td>

                  <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item.email}</td>
                  <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item.phonenumber}</td>
                  {(user?.usertype == "SuperAdmin") || (user?.usertype == "Admin") ||  (user?.usertype == "Supervisor" && user?.scheduleaccess) ?
               <>
                  <td className='pr-[15px]'>

                    <button
                      onClick={() => {

                        navigate('/ProjectDesignations', {
                          state: {
                            projectid: item.id
                          }
                        });
                      }}
                      type='submit' className="text-center w-full py-[5px] rounded-md bg-themeColor text-white text-sm PoppinsRegular uppercase font-medium ">Designations</button>

                  </td>
                  <td className='pr-[15px]'>

                    <button
                      onClick={() => {


                        navigate('/CreateEmployeeSchedule', {
                          state: {
                            projectid: item.id
                          }
                        });
                      }}
                      type='submit' className="text-center w-full py-[5px] rounded-md bg-themeColor text-white text-sm PoppinsRegular uppercase font-medium ">Schedule</button>

                  </td>
                  </> :null}

                  {(user?.usertype == "SuperAdmin") || (user?.usertype == "Admin") ||  (user?.usertype == "Supervisor" && user?.editaccess) ?
                  <td className='pr-[15px]'>

                    <button
                      onClick={() => {
                        navigate('/EditProject', {
                          state: {


                            project: item,


                          }
                        });


                      }}
                      type='submit' className="text-center w-full py-[5px] rounded-md bg-themeColor text-white text-sm PoppinsRegular uppercase font-medium px-[5px]">Edit</button>

                  </td>
         :null}



                </tr>
              )
              )
              }
            </tbody>
          </table>
        </div>
          {user?.usertype == "AAdmin" &&
          <>
        <div className='mt-12'>
          <h1 className='text-[22px] text-textcolor PoppinsRegular font-semibold'>Employees</h1>
        </div>
        <div className="overflow-x-auto mt-5  border border-gray-200 backdrop-blur-lg my-12 bg-cardOverlay rounded-xl  drop-shadow-lg overflow-y-scroll m-h-[45%]">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm ">
            <thead className="ltr:text-left rtl:text-right">
              <tr>
                <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor"></th>
                <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Name</th>
                {/* <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Designation</th> */}
                <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Email</th>
                <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Phone Number</th>

                {/* <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor pr-[15px]">Time Slot</th> */}


              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200 text-center">
              {employees && employees.map((item, index) => {

                let data = [...designationcolors]
                if (data?.find(data => data.designation == item.designation) == undefined) {
                  let object = {
                    designation: item.designation,
                    color: getRandomColor()
                  }
                  data.push(object)
                  setdesignationcolors(data)
                }

                let colorcode = data.find(itemdata => itemdata.designation == item.designation)

                return (
                  !item?.disabled &&
                  <tr
                  key = {index.toString()}
                  className='hover:bg-[#0989B826]/10 cursor-pointer'
                  // onClick={()=>{

                  // navigate('/dashboard', {state: {
                  //   employeedata: item
                  // }});
                  // }}
                  >
                    <td className="whitespace-nowrap px-4 py-2 flex justify-center font-medium PoppinsRegular text-textcolor">
                      <img src={item.profilepicture != "" ? item.profilepicture : userlogo} className='w-[30px] h-[30px] lg:w-[40px] lg:h-[40px] rounded-full' />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item.firstname} {item.lastname}</td>
                    {/* <td

                      className={`whitespace-nowrap px-4 py-2 `}>

                      <text
                        style={{ color: colorcode.color, backgroundColor: hexToRgba(colorcode.color, 0.3), }}
                        className={` PoppinsRegular font-medium rounded-full px-[10px] py-[5px]`}
                      >{item.designation}</text>
                    </td> */}
                    <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item.email}</td>
                    <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item?.phonenumber}</td>

                    {/* <td className=' pr-[15px]'>
                        {item?.projectassigned ?
                        <>
                      {item?.starttime && item?.endtime ?
                        <text className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{`${item.starttime} - ${item.endtime}`}</text>
                    :
                      <button
                      onClick={() =>{
                        navigate('/CreateEmployeeSchedule', {state: {
                          projectid: item.projectassigned,
                          employeedata: item
                        }});
                      }}
                      type='submit' className="text-center w-full py-[5px] rounded-md bg-themeColor text-white text-sm PoppinsRegular uppercase font-medium ">Assign</button>
}
</>
:  <text className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor"></text>}
</td> */}

                  </tr>
                )
              }
              )
              }
            </tbody>
          </table>
        </div>
        </>}
        <div className='mt-12 flex w-full justify-between items-center'>
          <p className='text-textcolor text-[22px] PoppinsRegular font-semibold'>General Slots</p>
          {generalemployeecheckindata?.length > 0 &&
<div className='flex flex-row'>
<button
        onClick={() => handleDownloadExcelGeneral()}
    type='submit' className="text-center px-[10px] py-[5px] rounded-md bg-themeColor text-white text-sm PoppinsRegular uppercase font-medium ">Export</button>

{user?.usertype == "Admin" &&
  recorddeletionloader ?
                      <div
                        className=' ml-[15px] py-2  w-full   flex items-center justify-center '>
                        <RotatingLines
                          visible={true}
                          height="25"
                          width="25"
                          strokeColor="#218fdb"

                          strokeWidth="5"
                          animationDuration="0.75"
                          ariaLabel="rotating-lines-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                      :

    <button
        onClick={() => {
          setconfirmdeletepopup(true)
          setdeletionmessage(`Are you sure you want to delete shifts between ${moment(startdate).format('DD-MM-YYYY')} - ${moment(enddate).format('DD-MM-YYYY')} ?`)
        }}
    type='submit' className="ml-[10px] text-center px-[10px] py-[5px] rounded-md bg-red-500 text-white text-sm PoppinsRegular uppercase font-medium ">Delete All</button>

    }
    </div>

}
        </div>
        <div className=' border border-gray-300 rounded-xl mb-20   bg-white  drop-shadow-lg mt-5 '>


          <div className='mt-[30px] lg:mt-10'>

            {/* <div className='w-[95%] mx-auto mt-5 lg:mt-10'>
<div className="grid grid-cols-2 gap-4 md:grid-cols-4 lg:gap-8">

<div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4 lg:p-8  bg-cardOverlay  drop-shadow-lg">
<p className="text-base text-textcolor PoppinsRegular font-semibold md:text-lg">Today's Work</p>
<div className="text-sm font-semibold text-textcolor/50 PoppinsRegular">{todayshours + " hrs"}</div>
</div>

<div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4 md:p-8  bg-cardOverlay  drop-shadow-lg">
<div className="text-base text-textcolor PoppinsRegular font-semibold md:text-lg">Weeks's Work</div>
<div className="text-sm font-semibold text-textcolor/50 PoppinsRegular">{weeklyhours + " hrs"}</div>
</div>


<div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4 md:p-8  bg-cardOverlay  drop-shadow-lg">
<div className="text-base text-textcolor PoppinsRegular font-semibold md:text-lg">Months's Work</div>
<div className="text-sm font-semibold text-textcolor/50 PoppinsRegular">{monthlyhours + " hrs"}</div>
</div>

<div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4 md:p-8  bg-cardOverlay  drop-shadow-lg">
<div className="text-base text-textcolor PoppinsRegular font-semibold md:text-lg">Total Work Time</div>
<div className="text-sm font-semibold text-textcolor/50 PoppinsRegular">{alltimehours + " hrs"}</div>
</div>

</div>
</div> */}
            {/* <div className='flex w-full items-center justify-center my-12'>
<div className='flex flex-col items-center'>
<img src={employeedata?.profilepicture} className='w-[130px] h-[130px] rounded-full object-cover  bg-cardOverlay  drop-shadow-lg' />
<div className='flex flex-col text-center pt-2'>
<text className='text-textcolor PoppinsRegular font-bold text-xl'>{employeedata?.firstname + " " + employeedata?.lastname}</text>
<text className='text-textcolor/60 PoppinsRegular font-semibold text-sm'>{employeedata?.designation}</text>
</div>
</div>
</div> */}

            <div className='w-[90%] lg:w-[95%] mx-auto flex flex-row flex-wrap justify-between items-center'>

              <div
                className='w-full lg:w-[24%]'>

                <div
                  className='flex flex-row items-end justify-between pb-1 '>
                  <p className='PoppinsRegular font-medium text-textcolor/80 text-sm '>Select Project</p>
                  {project != "" && <p
                    onClick={() => {

                      setproject("")
                      setdesignation([])

                    }}
                    className='ml-[5px] text-[13px] text-red-600 cursor-pointer hover:underline'>Clear</p>}
                </div>
                <div
                  onClick={() => setprojectselectionpopup(!projectselectionpopup)}
                  className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex">{project == "" ? "Select Project" : project.title}</div>


                  <ProjectPopUp

                                        style= {{top: "110px", left:"30px"}}
                                        visible ={projectselectionpopup}
                                        data = {clients}

                                        activateoutsidecontainerclick = {()=>{
                                          setprojectselectionpopup(false);
                                        }}


                                                    onClickcorrect={(item) => {
                                                      setproject(item)
                                                      let data = { ...item }
                                                      if (data?.designation?.length > 0) {
                                                        for (const key in data.designation) {
                                                          data.designation[key]["selected"] = false
                                                        }
                                                      }


                                                      setdesignation(data.designation)
                                                      setprojectselectionpopup(false)
                                                    }}
                                      />

              </div>
              {project != "" && designation?.length > 0 &&
                <div

                  className='w-full lg:w-[24%]'>


                  <div

                    className='flex flex-row items-end justify-between pb-1 mt-4 lg:mt-0  '>
                    <p className='PoppinsRegular font-medium text-textcolor/80 text-sm '>Designation</p>
                    {designation?.length > 0 && <p
                      onClick={() => {

                        setdesignation([])

                      }}
                      className='ml-[5px] text-[13px] text-red-600 cursor-pointer hover:underline'>Clear</p>}
                  </div>
<div className='w-full relative'>
                  <div

                    onClick={() => setdesignationselectionpopup(!designationselectionpopup)}
                    className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex flex-wrap" >{designation?.length == 0 || designation.find(data => data.selected == true) == undefined ? "Select Designation" : designation.filter(function (data) { return data.selected == true }).map(function (data) {
                      return <div
                        onClick={() => {
                          let dataset = [...designation]
                          const designationindex = dataset.findIndex(item => item.designation === data.designation);
                          dataset[designationindex].selected = false
                          setdesignation(dataset)
                        }}
                        className='bg-themeColor px-[5px] py-[1px] mr-[5px] rounded-md flex flex-row items-center mb-[2px]'><text className='text-white '>{data.designation}</text>
                        <IoIosCloseCircle

                          className=" text-white ml-[3px]  w-[15px] h-[15px] hover:cursor-pointer"
                        /></div>
                    })}


                  </div>



                    <DesignationPopUp

                  multi ={true}
                                visible ={designationselectionpopup}
                                data = {designation}
                                activateoutsidecontainerclick = {()=>{
                                  setdesignationselectionpopup(false);
                                }}

                                 onClick={(item, index) => {
                                  let data = [...designation]
                                  data[index].selected = !data[index].selected
                                  //         data.designation[index].selected = !data.designation[index].selected
                                  // setproject(data)

                                  setdesignation(data)
                                }}

                              />
</div>

                </div>
              }
              <div className='w-full lg:w-[24%] mt-4 lg:mt-0'>
                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>From</p>
                <DatePicker className='PoppinsRegular font-medium border border-themeColor rounded-xl w-full' selected={startdate} onChange={(date) => setstartdate(new Date(date.setHours(0, 0, 0, 0)))} />
              </div>
              <div className='w-full lg:w-[24%] mt-4 lg:mt-0'>
                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>To</p>
                <DatePicker className='PoppinsRegular font-medium border border-themeColor rounded-xl w-full' selected={enddate} onChange={(date) => setenddate(new Date(date.setHours(23, 59, 59, 59)))} />
              </div>
            </div>
            <div className='w-[90%] lg:w-[95%] mx-auto flex flex-row flex-wrap justify-between items-center'>
              <div className="overflow-x-auto rounded-lg border border-gray-200 my-5 w-full ">
                <table className="max-w-full divide-y-2 divide-gray-200 bg-white text-sm ">
                  <thead className="ltr:text-left rtl:text-right">
                    <tr>
                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 "></th>
                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor  "></th>
                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Date</th>
                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-10 "></th>
                      {/* <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Employee ID</th> */}
                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Employee Name</th>
                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Designation</th>
                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Checkin Time</th>
                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Checkout Time</th>
                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Hours Worked</th>
                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Checkin Location</th>
                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Checkout Location</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 text-center w-full">
                    {loading ?
                      <div
                        className=' py-10  w-full   flex items-center justify-center absolute'>
                        <RotatingLines
                          visible={true}
                          height="25"
                          width="25"
                          strokeColor="#218fdb"

                          strokeWidth="5"
                          animationDuration="0.75"
                          ariaLabel="rotating-lines-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                      :

                      generalemployeecheckindata.map((item, index) => (
                        designation.find(data => data.selected == true) == undefined || designation.find(data => data.selected == true && item.projectdesignation == data.designation) != undefined ?
                          <tr
                          key = {index.toString()}
                          className={` cursor-pointer ${item?.hoursapproved == false ? "bg-red-500/10" : "hover:bg-[#0989B826]/10"}`}>
               {user.usertype == "Supervisor" && user?.deleteaccess == true || user.usertype == "Admin"  || user.usertype == "SuperAdmin"  ?
                           item?.hoursapproved != true ?
                           <td
                              className="whitespace-nowrap px-2 py-2 PoppinsRegular font-medium ">
                              <MdDelete
                                onClick={() => {
setconfirmdeletepopup(true)
setdeletionmessage("Are you sure you want to delete this shift?")
setdeletiondata(item)

                                  // navigate('/UpdateSlot', {
                                  //   state: {
                                  //     edit: true,
                                  //     editdoc: item,
                                  //     projectid: item.projectid,
                                  //     employeedata: item.employeedata[0],
                                  //     projectdesignation: item.projectdesignation,

                                  //   }
                                  // });

                                }}
                                className=" text-lighttextGray hover:text-themeColor   w-[20px] h-[20px] hover:cursor-pointer"
                              />
                            </td> : <td></td>

                            : <td></td>
  }

                       {user.usertype == "Supervisor" && user?.editaccess == true || user.usertype == "Admin"  || user.usertype == "SuperAdmin"  ?
                           item?.hoursapproved != false ?
                           <td
                              className="whitespace-nowrap py-2 PoppinsRegular font-medium ">
                              <MdEditDocument
                                onClick={() => {

                                  navigate('/UpdateSlot', {
                                    state: {
                                      edit: true,
                                      editdoc: item,
                                      projectid: item.projectid,
                                      employeedata: item.employeedata[0],
                                      projectdesignation: item.projectdesignation,

                                    }
                                  });

                                }}
                                className=" text-lighttextGray hover:text-themeColor   w-[17px] h-[17px] hover:cursor-pointer"
                              />
                            </td> : <td></td>

                            : <td></td>
  }


                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                              {moment(item.checkindate).format("DD-MM-YYYY")}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor flex justify-center ">
                              <img src={item.profilepicture} className='w-[35px] h-[35px]  object-cover rounded-full' />
                            </td>
                            {/* <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
      {item.employeeid}
    </td> */}


                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                              {item.name}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor  ">
                              {item.projectdesignation}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                              {item.checkintime ? item.checkintime : "Not Available"}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                              {item.checkouttime ? item.checkouttime : "Not Available"}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                              {/* {item?.minutes ? parseInt(item?.minutes / 60) : 0} */}
                              {item?.minutes ? parseInt(item?.minutes / 60) + " hrs " + parseInt(item?.minutes % 60) + " mins" : "NA"}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor"
                              onClick={() => {
                                getlocationaddress(item?.checkinlatitude, item?.checkinlongitude)
                              }}>
                              {item?.checkinlatitude ? "View" : "Not Available"}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor"
                              onClick={() => {
                                getlocationaddress(item?.checkoutlatitude, item?.checkoutlongitude)
                              }}>
                              {item?.checkoutlatitude ? "View" : "Not Available"}
                            </td>
                          </tr>
                          : null
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>

          </div>

        </div>

        <Confirmationpopup
visible = {confirmdeletepopup}
picture = {employeedata?.profilepicture}
text = {deletionmessage}
accept = {()=>{
if(deletionmessage == "Are you sure you want to delete this shift?"){
  deletesinglerecord()

}else{
  handleDeleteGeneral()
}

  setconfirmdeletepopup(false)

}}
cancel = {()=>{
  setconfirmdeletepopup(false)
}}

/>


      </div>
    )
  }


  const renderPopup = () => {
    switch (selectedtile) {
      case "Dashboard":
        return <Dashboard />;
        case "Slots":
          return <Slots />;
      case "Leave Request":
        return <LeaveRequest />;
      case "Create User":
        return <CreateEmployee />;
      case "Employees":
        return <HoursWorked />;
      case "Create Project":
        return <CreateProject />;
      case "Assign Project":
        return <AddEmployeeToProject />;
      case "Blogs":
        return <Blogs />;








      default:
        return null;
    }
  };
  return (
    <div className='w-[100%] flex flex-row'>


      <div className="hidden md:flex md:w-[25%]">
        <SideMenu />
      </div>

      <div className=" w-full md:w-[75%] h-screen overflow-scroll bg-white px-[20px] py-[30px] flex justify-center">


        {renderPopup()}
      </div>
    </div>
  )
}


export default AdminHome