import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from "react-toastify";
import { userlogin, getuserinformation, resetpassword } from '../../utils/firebasefunctions';
import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import WhiteLogo from '../images/whitelogo.png'
import Logo from '../images/logo.png'
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import Loader from "react-js-loader";
import { getAuth, onAuthStateChanged , signOut} from "firebase/auth";
import './index.css'
import { setLocationType } from 'react-geocode';
import { FaSalesforce } from 'react-icons/fa';

const SignInPopup = (props) => {
    const auth = getAuth();
    const { name, onClose, onNext } = props;
    const navigate = useNavigate();
    const [{ }, dispatch] = useStateValue();
    const [laoding, setlaoding] = useState(false);
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [forgotpassword, setforgotpassword] = useState(false);
    const [passwordvisible, setpasswordvisible] = useState(false);
    const handleNext = (popupname) => {
        onNext(popupname); // Replace "NextPopupName" with the actual name
    };

    const handleemailChange = (event) => {
        setemail(event.target.value)

    }

    const handlepasswordChange = (event) => {
        setpassword(event.target.value)

    }



    const forgotpasswordhandler = async () => {
        if (email == "") {
            toast.warn("Please enter your email")
            return
        } else {
            setlaoding(true)

            await resetpassword(email).then(async (response) => {

if(response == "success"){
    toast.success("Password reset email has been sent to your email!");
    setemail("")
    setforgotpassword(false)
}else{
    toast.warn("Your password could not be reset. Please verify your email!");
}

                setlaoding(false)

            })
                .catch((error) => {
                    toast.error(error);
                    setlaoding(false)
                });
        }


    }

    const proceedhandler = async () => {
        if (email == "") {
            toast.warn("Please enter your email")
            return
        } else if (password == "") {
            toast.warn("Please enter your password")
            return
        } else {
            setlaoding(true)
            await userlogin(email, password).then(async (response) => {

                if (response?.uid) {

                    await getuserinformation("users", response?.uid).then(async (response) => {

                        if(response?.delete){
                            setlaoding(false)
                            toast.warn(`Sorry for the inconvenience. Your account has been deleted. Please contact Administrator.`)
                            signOut(auth).then(() => {

                            }).catch((error) => {
                           console.log("sign out error")
                            });
                        }  else if(response?.disabled){
                            setlaoding(false)
                            toast.warn(`Sorry for the inconvenience. Your account has been disabled. Please contact Administrator.`)
                            signOut(auth).then(() => {

                            }).catch((error) => {
                           console.log("sign out error")
                            });
                        }  else if(response.usertype != "Employee" && response.usertype != "Project"){
                        dispatch({
                            type: actionType.SET_USER,
                            payload: response
                        })
                        // localStorage.setItem('user', JSON.stringify(response))
                        setlaoding(false)
                        toast.success("Signed in!")
                        navigate('/home')
                    }else if (response.usertype == "Project"){
                        setlaoding(false)
                        toast.warn("Projects dashboard is under maintainance. You are instructed to sign in with your Admin account!")

                    }else {
                        setlaoding(false)
                        toast.warn("Employees are instructed to sign in using the Raftek Mobile Application")
                        signOut(auth).then(() => {

                        }).catch((error) => {
                       console.log("sign out error")
                        });
                    }
                    }).catch((err) => {
                        alert(err)
                        setlaoding(false)
                    })


                } else {
                    toast.error(response.code)
                    setlaoding(false)
                }

            })
                .catch((error) => {
                    toast.error(error);
                    setlaoding(false)
                });

        }

    }

    return (
        <main className='w-full h-screen flex items-center justify-center'>
            <section className='w-full flex justify-between h-full'>
            <div className='hidden md:w-full md:flex justify-center items-center  bg-themeColor rounded-tr-3xl rounded-br-3xl'>
                    <img src={WhiteLogo} className='w-[50%] object-contain' />
                </div>
                <div className=' mx-auto w-full flex flex-col justify-center  bg-white  '>

                    <div className='flex '>

                        <div className='w-full lg:w-[90%] mx-auto py-3 px-7 rounded-[30px]'>
                            <div className="md:hidden w-full flex justify-center mb-[20px]">
                            <img src={Logo} className='md:hidden w-[50%] object-contain' />
                            </div>

                            <div className='flex justify-between pt-3'>
                                <p className="text-textcolor text-[30px] lg:text-[40px] font-bold PoppinsRegular">{forgotpassword ? "Forgot Password" : "Sign In"}</p>
                            </div>
                            <div className='mt-6'>
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Email</p>
                                <input type='text' placeholder='Email'
                                    value={email}
                                    onChange={handleemailChange}

                                    className="input  px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex"
                                />
                            </div>
                            {!forgotpassword &&


                            <div className='mt-6 relative'>
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Enter password</p>
                                <input
                                    value={password} placeholder='Enter password'
                                    onChange={handlepasswordChange}
                                    type={passwordvisible ? "text" : "password"} className="px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                                <div
                                onClick={() => {setpasswordvisible(!passwordvisible)}}
                                className='absolute top-[36px] right-[10px]'>
                              {passwordvisible ?
                                <IoMdEye

className="text-themeColor w-[25px] h-[25px]"
/>
:
<IoMdEyeOff

className="text-themeColor w-[25px] h-[25px]"
/>

    }

                                </div>
                            </div>
}

{!forgotpassword &&
                            <p
                            onClick={()=>{
                                setforgotpassword(true)}}
                            className='PoppinsRegular font-medium text-textcolor/80 text-sm pt-[10px] text-right hover:cursor-pointer hover:underline hover:text-themeColor'>{"Forgot Password?"}</p>
}

{laoding ?
<Loader type="spinner-cub" bgColor={"#218fdb"}  size={30} /> :
                            <button type='submit' className="text-center py-4 rounded-xl mt-6 bg-themeColor hover:bg-themeColor/80 w-full text-white text-base font-semibold PoppinsRegular uppercase" onClick={() => {
                            if(forgotpassword){
                                forgotpasswordhandler()
                            }else{
                                proceedhandler()
                            }
                            }}>{forgotpassword ? "Reset Password" : "Continue"}</button>}
                            {/* <div className="w-full h-[1px] mt-6 bg-gray-300"></div> */}
                            {/* <div className='flex justify-center py-6'>
                                <p className="text-center text-textcolor text-sm font-medium PoppinsRegular">
                                    Don’t have an account?
                                </p>
                                <p className="text-center pl-3 cursor-pointer text-textcolor text-sm font-bold PoppinsRegular"
                             onClick={() => handleNext('Signup')}
                                >
                                    Sign Up
                                </p>
                            </div> */}


{forgotpassword &&
                            <p
                            onClick={()=>{
                                setforgotpassword(false)}}
                            className='PoppinsRegular font-medium text-textcolor/80 text-sm mt-[20px] text-center hover:cursor-pointer hover:text-themeColor hover:underline'>{"Sign In"}</p>
}
                        </div>
                    </div>

                </div>


            </section>
        </main>
    )
}


export default SignInPopup